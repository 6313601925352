<script>
    import Layout from "@/views/layouts/static/main";
    import PageHeader from "@/components/static/page-header";
    import appConfig from "@/app.config";
    import CreateForm from "./components/createForm.vue";
import moment, { min } from 'moment';

    
    export default {
        page: {
            title: "Create Shift",
            meta: [
            {
                name: "description",
                content: appConfig.description,
            },
            ],
        },
        components: {
            Layout,
            PageHeader,
            CreateForm
        },
        data() {
            return {
                processing:false,
                renderCreateForm:true,
                myarray: [],
                title: "Create Shift",
                items: [
                    {
                        text: "Shift",
                    },
                    {
                        text: "Create Shift",
                        active: true,
                    },
                ],
                roles: [
                    {
                        id: null,
                        count: null,
                        role_count: null,
                        remaining_slots: null,
                    }
                ],
                shift: { 
                    shift_type:'Non_Premium',
                    label_color:'#0a0a0a',
                    bonus:null,
                    name:null,
                    interval: null,
                    chunk: null,
                    from: null,
                    to: null,
                    location: null,
                    desc: null,
                    location_id:null,
                },                
                location_id:null,
                cloneShiftsModel:false,
                duplicateFrom:null,
                duplicateTo:null,
                shift_id:null,
                shiftTimeBreaks:[],
                timebreakshiftfrom: null,
                timebreakshiftto: null,
                locations: [],

                
            };
        },

  
    watch:{
        'shift.from'(newVal){
            const format1 = "YYYY-MM-DD HH:mm:ss";
            this.timebreakshiftfrom = moment(this.shift.from).format(format1);
            this.shift.chunk =null;
            this.shift.chunk =1;
        },

        'shift.to'(newVal){
            if(newVal <= this.shift.from){
                this.triggerSwal("shift to time must be greater then from time", "error");
            }
                const format1 = "YYYY-MM-DD HH:mm:ss";
                this.timebreakshiftto = moment(this.shift.to).format(format1);
                this.shift.chunk =0;
                this.shift.chunk =1;
            
        },
        'shift.chunk' :{
            handler : function(newVal){
                if(this.shift.from && this.shift.to && newVal != null)
                {
                    this.shiftTimeBreaks = [];
                    let intervalDiff=this.hoursTimeDiff(this.timebreakshiftfrom,this.timebreakshiftto)
                    let ratio= this.determineRatio(intervalDiff,this.shift.chunk);
                    let timeBreak=this.numbertoHours(ratio);
                    if(this.shift.chunk>1){
                        this.addHoursTime(this.timebreakshiftfrom,timeBreak,this.shift.chunk);
                    }else{
                        this.shiftTimeBreaks.push({from:this.changeDateTimeToUTCZero(this.shift.from),to:this.changeDateTimeToUTCZero(this.shift.to),type:'Full'});
                    }
                }
            },deep : true,
        }
       
    },
    mounted() {
        this.locations = this.$store.state.auth.currentUser.employee.locations;
        this.location_id = this.locations[0].id;
    },

        methods: {
            closeCloneShiftModel(){
                this.cloneShiftsModel = false;
                this.$router.push('/shifts');
            },
           
            cloneShiftsToDate() {
                let self = this;
                    if (self.processing) {
                        return;
                    }
                    self.processing = true;
                    
                    self.$axios.post('/shift/store-cloned-shifts-to-date', {
                        duplicateFrom:this.duplicateFrom,
                        duplicateTo:this.duplicateTo,
                        shift_id:this.shift_id,
                        shifttimebreak:this.shiftTimeBreaks,

                }).then(response => {
                    let data = response.data;

                    self.triggerSwal(data.message, "success");
                        this.renderTable=false
                            self.cloneShiftsModel=false;
                            this.$nextTick(() => {
                        this.renderTable=true
                            });
                    
                    self.$router.push('/shifts');
                                     
                })
                .catch(error => {
                    self.cloneShiftsModel=false;
                    self.handleErrorResponse(error.response, "error");
                    
                })
                .finally(function () {
                    self.processing = false;
                });
            },

    uploadShift() {
        let self = this;
            if(this.$refs.createForm.chunkError === true)
            {
                self.triggerSwal("Chunks Cannot be greater than 3", "error");
                return;
            }
            if(this.roles.length <= 0) {
                self.triggerSwal("Role is required", "error");
                return;
            }
            this.roles.forEach(role => {
                if(role.id == null) {
                    self.triggerSwal("Role is not Selected", "error");
                    return;
                } else if(role.count <= 0) {
                    self.triggerSwal("There Should be Atleast 1 Role Count", "error");
                    return;
                }
            });
            this.$refs.createForm.$v.$touch();
            // if (this.$refs.createForm.$v.shift.name.$invalid === true ||
            //     this.$refs.createForm.$v.shift.chunk.$invalid === true ||
            //     this.$refs.createForm.$v.shift.from.$invalid === true || 
            //     this.$refs.createForm.$v.shift.interval.$invalid === true || 
            //     this.$refs.createForm.$v.shift.to.$invalid === true ||
            //     this.$refs.createForm.$v.shift.location_id.$invalid === true)
            if(this.$refs.createForm.$v.$invalid)
            {
                self.triggerSwal("Fields Cannot be empty", "error");
                return;
            }
            
            if (self.processing) {
                return;
            }
            self.processing = true;

            self.$axios.post('/shift/store', {
                name: this.shift.name,
                from: this.shift.from,
                to: this.shift.to,
                interval: this.shift.interval,
                shift_type: this.shift.shift_type,
                chunk: this.shift.chunk,
                bonus: this.shift.bonus,
                location_id: this.shift.location_id,
                roles: this.roles,
                label_color: this.shift.label_color,
                //utc_from/to is to save time in shift area roles.
                utc_from: this.changeDateTimeToUTCZero(this.shift.from),
                utc_to: this.changeDateTimeToUTCZero(this.shift.to),
          })
                .then(response => {
                    let data = response.data;

                    self.triggerSwal(data.message, "success");
                    this.shift_id = data.payload.id;
                    this.cloneShiftsToDate(this.shift_id);
                    this.cloneShiftsModel = true;
                    
                    // if(this.shift.duplicate){
                    // this.shift.duplicate_status=true;
                    // this.shift.duplicate=false;
                    // }
                    // else{
                    //         self.$router.push('/shifts');
                    // }

                                     
                })
                .catch(error => {
                    self.handleErrorResponse(error.response, "error");
                    
                })
                .finally(function () {
                    self.processing = false;
                });
    },

    hoursTimeDiff(startTime,endTime){
         
    var todayDate=this.getCurrentDate();  //  return in format YYYY-MM-DD 
    var startDate = new Date(`${startTime}`);
    var endDate = new Date(`${endTime}`);
    var timeDiff = Math.abs(startDate.getTime() - endDate.getTime());

    var hh = Math.floor(timeDiff / 1000 / 60 / 60);   
    hh = ('0' + hh).slice(-2)
   
    timeDiff -= hh * 1000 * 60 * 60;
    var mm = Math.floor(timeDiff / 1000 / 60);
    mm = ('0' + mm).slice(-2)

    timeDiff -= mm * 1000 * 60;
    var ss = Math.floor(timeDiff / 1000);
    ss = ('0' + ss).slice(-2)
    
    // alert("Time Diff- " + hh + ":" + mm + ":" + ss);
      return {hh:hh , mm:mm , ss:ss};
     },

     determineRatio(intervalDiff, duration) {
      let totalHours=intervalDiff.hh+ ' : ' + intervalDiff.mm+ ' : ' + intervalDiff.ss;

    var totalHoursArray = totalHours.split(':');

    var numerator = parseInt(totalHoursArray[0])*3600 
        + parseInt(totalHoursArray[1])*60 
        + parseInt(totalHoursArray[2]);

     var res=numerator/duration;
    return res/3600

    },

    numbertoHours(value){
    var hours = Math.floor(value);
        var minutes = Math.round((value % 1)*100)/100;

        return {hours:hours, minutes:minutes};       

    },

    addHoursTime(startTime,breakAbleTime,chunks){
        this.shiftTimeBreaks=[];
        let from=startTime;
        const format1 = "YYYY-MM-DD HH:mm:ss";
        var todayDate=this.getCurrentDate();  //  return in format YYYY-MM-DD 
        for(let i=1;i<=chunks;i++){
            var dt = new Date(`${from}`);
            dt.setHours(dt.getHours() + breakAbleTime.hours);
            dt.setMinutes(dt.getMinutes() + breakAbleTime.minutes);
            let to =moment(dt).format(format1);
            this.shiftTimeBreaks.push({from:this.changeDateTimeToUTCZero(from),to:this.changeDateTimeToUTCZero(to),type:'Half'});
            from=to;
        }
    },
     
        },

        middleware: "authentication",
    };
</script>

<template>
    <Layout>
        <PageHeader :items="items" :title="title" />
            <div class="card p-4 radius-10">
            <create-form v-if="renderCreateForm" :roles="roles" :location_id="location_id" :shift="shift" ref="createForm" ></create-form>
                <div class="col-md-12">
                    
                <SubmitButton text="Create Shift" @clicked="uploadShift" :processing="processing"></SubmitButton>
            </div>

            <b-modal
                v-model="cloneShiftsModel"
                title="Select date range to duplicate shifts"
                title-class="text-black font-18"
                body-class="p-3"
                hide-footer
                >
            <form id="releaseShift">
                <div class="row">
                    <div class="col-md-6 mb-2">
                        <div class="align-items-center d-flex gap-2">
                            
                            <div class="form-field__wrapper w-100">
                                <b-form-group id="example-dateFrom" label="Duplicate Shift From" label-for="dateFrom">
                                    <b-form-input id="dateFrom" v-model="duplicateFrom" :min="getCurrentDate()" type="date"></b-form-input>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-2">
                        <div class="align-items-center d-flex gap-2">
                            
                            <div class="form-field__wrapper w-100">
                                <b-form-group id="example-dateTo" label="Duplicate Shift To" label-for="dateTo">
                                    <b-form-input id="dateTo" v-model="duplicateTo" :min="duplicateFrom" type="date"></b-form-input>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    
                </div>

                <div class="text-end pt-5 mt-3">
                <button  @click="closeCloneShiftModel" type="button" class="btn btn-outline-pink mx-3">Close</button>
                <b-button @click="cloneShiftsToDate" variant="success" class="ms-1"
                    >Duplicate</b-button
                >
                </div>
            </form>
            </b-modal>


        </div>
    </Layout>
</template>
